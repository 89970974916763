import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useMutation, useQueryClient } from "react-query";
import axiosClient from "../../../api/axiosClient";
import { createUserApi, resendSMSVerification } from "../../../api/users.ts";
import {
  SIGN_UP_COLLECT_BASIC_INFO_STEP,
  SIGN_UP_PROCESS_STEP,
} from "../../../commons/signup.constants";
import { LoadingBackdrop } from "../../../components/loading-backdrop";
import DataLoadingContext from "../../../context/data-loading-context";
import SignupContext from "../../../context/sign-up-context";
import { useQueryInvalidationWithNotification } from "../../../hooks";
import { convertPhoneNumberToStar } from "../../../utils/email-verification";
import { isBrowser } from "../../../utils/miscellaneous-util";
import { cognitoLogin } from "../../../utils/user-auth-provider";
import { splitNameFromFullName } from "../../../utils/user-data-util";

const PhoneVerifyContainer = ({ timeExpire = 0 }) => {
  const {
    setSignupProcess,
    setCollectBasicInfoStep,
    setParams,
    params,
    fieldError,
    setFieldError,
  } = useContext(SignupContext);
  const invalidateQueries = useQueryInvalidationWithNotification();
  const queryClient = useQueryClient();
  const { setAppState } = useContext(DataLoadingContext);
  const [otp, setOtp] = useState("");
  const [expireCountDownSeconds, setExpireCountDownSeconds] = useState(
    timeExpire || 60
  );
  const [open, setOpen] = useState(false);

  const resetSmsVerifyStatus = () => {
    setFieldError((previousFieldError) => ({
      ...previousFieldError,
      smsVerifyStatus: "",
    }));
  };
  const { phoneNumber, email, firstName: userName } = params;

  const [firstName, lastName] = splitNameFromFullName(userName);

  useLayoutEffect(() => {
    resetSmsVerifyStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (expireCountDownSeconds <= 0) {
        clearInterval(interval);
      }
      setExpireCountDownSeconds((timer) => timer - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [expireCountDownSeconds]);

  const { smsVerifyStatus = "" } = fieldError;
  const handleEditPhoneNumber = () => {
    setParams({ ...params, phoneNumber: "" });
    setSignupProcess(SIGN_UP_PROCESS_STEP.COLLECT_BASIC_INFO);
    setCollectBasicInfoStep(SIGN_UP_COLLECT_BASIC_INFO_STEP.PHONE_NUMBER);
  };

  const createUserMutation = useMutation(
    (userParams) => createUserApi(userParams),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData("myInfo", response);
        setAppState({ shouldPauseAuthenticatedRedirect: true });
        const cognitoResponse = await cognitoLogin(
          params.email,
          params.password
        );
        axiosClient.defaults.headers.common.authentication =
          cognitoResponse.signInUserSession.accessToken.jwtToken;
        invalidateQueries("isLoggedIn");
        setSignupProcess(SIGN_UP_PROCESS_STEP.CONNECT_BANK);
      },
      onError: (error) => {
        const { data: { message = "" } = {} } = error;
        setFieldError((preState) => ({
          ...preState,
          smsVerifyStatus:
            message || "Cannot create account. Please try again.",
        }));
      },
    }
  );

  const handleResentOTP = async () => {
    const resendCodeID = await resendSMSVerification(phoneNumber, email);
    setOpen(true);
    const { code_id = "" } = resendCodeID;
    setParams((prevState) => ({ ...prevState, codeId: code_id }));
    setExpireCountDownSeconds(60);
  };

  const handleVerifyOTP = (otpCode) => {
    createUserMutation.mutate({
      ...params,
      code: otpCode,
      firstName,
      lastName,
      phoneNumber,
    });
  };

  const handleInputOtp = (newOtp) => {
    setOtp(newOtp);
    if (newOtp?.length >= 6) {
      handleVerifyOTP(newOtp);
    } else {
      resetSmsVerifyStatus();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="body-context-container">
      <div className="signup-context-title">We sent you a text.</div>
      <div className="verify-otp-container-description">
        Number we have ends in {convertPhoneNumberToStar(phoneNumber)}
        <span
          role="button"
          onKeyDown={() => {}}
          tabIndex="0"
          onClick={handleEditPhoneNumber}
          className="verify-otp-button"
        >
          Click here to edit.
        </span>
      </div>

      <OtpInput
        containerStyle="otp-container"
        inputStyle="otp-input"
        value={otp}
        onChange={handleInputOtp}
        numInputs={6}
        shouldAutoFocus
        isInputNum
      />
      <div className="mt-3 text-danger">{smsVerifyStatus}</div>
      <div className="verify-otp-container-description">
        Didn&apos;t receive a code?{" "}
        {expireCountDownSeconds > 0 ? (
          `Resend it in ${expireCountDownSeconds}s`
        ) : (
          <span
            className="verify-otp-button"
            role="button"
            onKeyDown={() => {}}
            tabIndex="0"
            onClick={handleResentOTP}
          >
            Resend it
          </span>
        )}
      </div>
      {createUserMutation.isLoading && <LoadingBackdrop />}

      {isBrowser() && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <MuiAlert elevation={6} variant="filled" severity="success">
            SMS verification has been sent!
          </MuiAlert>
        </Snackbar>
      )}
    </div>
  );
};

PhoneVerifyContainer.propTypes = {
  timeExpire: PropTypes.number,
};

export default PhoneVerifyContainer;
